#score-overview-container {
    position: absolute;
    width: 100%;
    height: 75%;
    margin-top: 15%;
    font-size: 1.5rem;
    font-family: 'bulo-rounded';
    text-transform: uppercase;

    background-image: url('/game/final-score-background.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    
    #score-overview {
        position: relative;
        transform: rotate(-2deg);
        margin: 17rem 3rem 0rem 3rem;

        #title {
            width: 100%;
            text-align: center;
            font-size: 3.75rem;
            color: rgb(255, 165, 0);
            letter-spacing: 0.25rem;
        }

        #line {
            width: 93%;
            height: 0.1rem;
            margin: auto;
            background-color: rgb(31, 31, 31);
        }
        #line.negative {
            margin-top: -7.75rem;
            margin-bottom: 7.75rem;
        }

        #score-container{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 1.5rem 0rem;
    
            .score-item {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 25%;
    
                .rank {
                    transform: rotate(-15deg);
                    color: rgb(255, 165, 0);
                    font-size: 3rem;
                }
                .name {
                    position: relative;
                    font-size: 2.25rem;
                    margin-bottom: -0.5rem;
                }
                .name.wobble {
                    animation: name-wobble-keyframes 0.2s ease-out;
                }
                .score {
                    color: #ff7800;
                    font-size: 2.25rem;
                }
    
                .score-bar {
                    mask-image: url('/game/score_bar_fill.png');
                    mask-size: contain;
                    mask-repeat: repeat-y;
                    margin: 1rem;
                    position: relative;
                    height: 22rem;
                    width: 5rem;
    
                    .fill {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-color: red;
                        width: 100%;
                    }
                }
    
                .crown {
                    position: absolute;
                    width: 100%;
                    height: 6rem;
                    top: -3rem;
                    right: -1rem;
    
                    background-image: url("/game/crown.png");
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;

                    &.winner {
                        animation: crown-appear-keyframes 0.3s ease-out;

                        @keyframes crown-appear-keyframes {
                            0% { transform: scale(1, 1) rotate(5deg); }
                            50% { transform: scale(1.5, 1.5) rotate(25deg); }
                            100% { transform: scale(1, 1) rotate(0deg); }
                        }
                    }
                }

                @keyframes name-wobble-keyframes {
                    0% { transform: scale(1, 1) rotate(0deg); }
                    50% { transform: scale(1.3, 1.3) rotate(-5deg); }
                    100% { transform: scale(1, 1) rotate(0deg); }
                }
            }
        }

        #dennis-note {
            position: absolute;
            width: 100%;
            margin-top: 2rem;
            
            li {
                width: 100%;
                height: 2rem;
                float: right;
                transform: rotate(-4deg);
                padding: 0.2rem 0rem;
                list-style-type: none;
                color: rgb(65, 83, 119);
                font-size: 2rem;
                text-align: right;
                font-family: 'janitor';

                &:nth-child(1) {
                    margin-right: 3rem;
                }
                &:nth-child(2) {
                    margin-right: 8rem;
                }
                &:nth-child(3) {
                    margin-right: 4.5rem;
                }
            }
        }
    }
}
