#mission-panel {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    pointer-events: none;
    font-family: 'bulo-rounded';
    text-transform: uppercase;
    color: black;

    #info-button {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 5.5rem;
        height: 5.5rem;
        background-image: url('/game/buttons/btn_info.png');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        pointer-events: all;
    }
    #info-button.pressed {
        background-image: url('/game/buttons/btn_info_down.png');
    }

    #content {
        background-color: #e9f0ff;
        margin-bottom: -1px;
        padding: 0 3rem;
    }

    #bottom-bar {
        width: 100%;
        margin-top: -0.1rem;
        
        img {
            width: 100%;
        }

        #room-code {
            position: absolute;
            right: 0;
            width: 13rem;
            height: 3rem;
            margin: 1rem 3rem 0 auto;
            font-size: 2rem;
            text-align: center;
            background-color: rgba(116, 160, 211, 0.25);
            border-radius: 2rem;
            -webkit-border-radius: 2rem;
            padding: 0.3rem 0rem;
            letter-spacing: 0.12rem;
            line-height: 3rem;
    
            #code {
                color: rgb(57, 57, 196);
            }
        }

        #mute-button {
            position: absolute;
            right: 0;
            width: 3rem;
            height: 3rem;
            margin: 5rem 3rem 0 auto;
            background-color: rgba(116, 160, 211, 0.25);
            border-radius: 2rem;
            -webkit-border-radius: 2rem;
            padding: 0.3rem;
            pointer-events: all;

            #icon {
                width: 100%;
                height: 100%;
                background-image: url('/game/icons/icn_audio_volume.png');
                background-size: 2rem;
                background-position: center center;
                background-repeat: no-repeat;

                &.muted {
                    background-image: url('/game/icons/icn_audio_mute.png');
                }
            }
        }
    }

    #round-info {
        font-size: 2.5rem;
        line-height: 2.5rem;
        padding: 4rem 0 0.25rem 0;
        
        #round-number {
            color: #18222b;
        }
        #round-time {
            color: #ff7800;
        }
        #round-time.animate {
            animation: round-time-update-animation 0.2s ease-out;
        }
    }

    @keyframes round-time-update-animation {
        0% {
            transform: scale(1, 1);
        }
        25% {
            transform: rotate(3deg);
        }
        50% {
            transform: scale(1.2, 1.2) translate(0, -0.5rem);
        }
        75% {
            transform: rotate(-3deg);
        }
        100% {
            transform: scale(1, 1) rotate(0) translate(0, 0);
        }
    }

    #mission-content {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 0.25rem 0 0 0;

        .mission-item {
            font-size: 2.5rem;
            line-height: 3rem;
            padding: 0.5rem 1rem;
            width: 44%;
            height: 10rem;
            background-color: rgba(116, 160, 211, 0.25);
            border-radius: 1.25rem;
            -webkit-border-radius: 1.25rem;
            display: flex;
            flex-direction: column;
            pointer-events: all;

            .mission-title {
                color: #18222b;
                word-wrap: break-word;
                height: 65%;

                &.completed {
                    opacity: 0.6;
                    transition-duration: 0.2s;
                }
            }
            .mission-description > span.number {
                color: rgb(189, 113, 0);
            }
            .mission-description.open {
                opacity: 1;
                height: 100%;
                transition: opacity 0.2s ease 0.2s, 
                            height 0.2s ease 0.05s;
            }
            .mission-description.close {
                opacity: 0;
                height: 0%;
                transition: opacity 0.2s ease 0s, 
                            height 0.2s ease 0.15s;
            }
            .mission-target {
                position: relative;
                margin: 0.5rem 0 1.25rem 0;
            }
            
            .progress-bar{
                position: relative;
                width: 95%;
                margin: auto;
                z-index: unset;
                border-radius: 0.8rem;
                -webkit-border-radius: 0.8rem;
            }
            .progress-bar .progress-text {
                position: absolute;
                width: 100%;
                height: 100%;
                font-size: 1.75rem;
                text-align: center;
                line-height: 100%;
                color: white;
            }
                
            &.open {
                height: 40rem;
                transition: height 0.35s;
            }
            &.close {
                transition: height 0.35s ease 0.1s;
            }
        }
    }
    
    .mission-target.animate {
        animation: mission-target-update-animation 0.2s ease-out;
    }
    
    @keyframes mission-target-update-animation {
        0% {
            transform: scale(1, 1);
        }
        25% {
            transform: rotate(8deg);
        }
        50% {
            transform: scale(1.3, 1.3) translate(0, -1rem);
        }
        75% {
            transform: rotate(-8deg);
        }
        100% {
            transform: scale(1, 1) rotate(0) translate(0, 0);
        }
    }

    #click-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .checkmark-container {
        position: absolute;
        top: -1rem;
        left: 1rem;
        width: 3rem;
        height: 3rem;

        .checkmark {
            display: inline-block;
            width: 0%;
            height: 0%;

            transform: rotate(-8deg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            &.completed {
                width: 100%;
                height: 100%;
                background-image: url("/game/checkmark.png");
                transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
        }

        &.animate {
            animation: checkmark-container-keyframes 0.3s ease;

            @keyframes checkmark-container-keyframes {
                0% { transform: scale(1); }
                70% { transform: scale(4) rotate(-5deg); }
                100% { transform: scale(1); }
            }
        }
    }
}
