#game-starting-panel {
    position: absolute;
    text-align: center;
    font-size: 5em;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #4d628d;
    background-image: url(/background_pattern.png), linear-gradient(0deg, #4d628d 0%, #34425f 100%);
    z-index: 10;
    user-select: none;


    #traffic-light {
        position: absolute;
        background-image: url("/game/UIMultiplayer_Image_Trafficlight.png");
        top: 30%;
        bottom: 25%;
        width: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    #top-bar,
    #bottom-bar,
    #middle-bar {
        position: absolute;
        background-image: url("/game/player_name_background.png");
        background-size: cover;
        background-position: center;
        font-size: 3rem;
        line-height: 4rem;
        height: 5rem;
        width: 110%;
        left: -5%;
        color: #000000;
    }

    #player-number {
        position: absolute;
        background-size: contain;
        top: 12%;
        right: 1.5%;
        width: 15rem;
        height: 15rem;
        transform: rotate(5deg);
        background-position: center;
        background-repeat: no-repeat;

        #player-number-text {
            font-size: 6.5rem;
            text-align: center;
            padding: 0.5rem 0;
        }
    }

    #top-bar {
        position: absolute;
        top: 13%;
        transform: rotate(12deg);

        #player-name {
            text-align: center;
            padding-right: 8rem;
        }
    }

    #middle-bar {
        top: 20%;
        transform: rotate(-6deg);

        #code {
            font-size: 2.5rem;
            text-align: center;
            padding-right: 8rem;
        }
    }

    #bottom-bar {
        position: absolute;
        bottom: 10%;
        transform: rotate(-12deg);
    }

    img {
        position: absolute;
        top: 20%;
        left: 20%;
        display: block;
        max-width: 60%;
        max-height: 60%;
        width: auto;
        height: auto;
    }
}
