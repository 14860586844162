#game-page {
    overflow: hidden;
    height: 100%;
    position: relative;
    text-align: center;
    font-size: 1rem;
    user-select: none;

    #pause-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-size: 4rem;
            color: white;
            text-shadow: 1rem 1rem rgba(0, 0, 0, 0.514);
            padding: 3rem;
        }
    }

    #header {
        position: absolute;
        top: -1rem;
        width: 100%;
        left: -7.5%;
        transform: rotate(-12deg);
        z-index: 5;
        font-size: 2rem;
        color: black;
        background-image: url("/game/player_name_background.png");
        background-size: cover;
        background-position-x: -11rem;
        filter: drop-shadow(0 0.5rem 0 rgba(0, 0, 0, 0.18));

        #player-name {
            text-align: left;
            margin: 0rem 0 0.25rem 10rem;
        }

        #player-id {
            position: absolute;
            top: -1rem;
            left: 0;
            width: 5rem;
            height: 5rem;
            color: black;
            font-size: 2rem;
            margin-left: 3.5rem;
            color: white;
            padding: 0.2rem;
            font-size: 2.5rem;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    #player-score {
        position: absolute;
        top: 5%;
        left: 60%;
        font-size: 3rem;
        transform: rotate(-2deg);
        z-index: 4;
        color: #242e4d;
        min-width: 20rem;
        text-align: center;
        background-image: url("/game/score_label_background.png");
        background-size: cover;
        filter: drop-shadow(1rem 1rem 0px rgba(0, 0, 0, 0.18));
    }

    position: absolute;
    bottom: 0px;
    width: 100%;

    .actor-button {
        position: relative;
        width: 10rem;
        height: 10rem;
        font-size: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: bottom;
        filter: drop-shadow(17px 10px 0px rgba(0, 0, 0, 0.18));
        transform-origin: center bottom;

        .icon-background {
            position: relative;
        }

        .background {
            width: 100%;
            opacity: 0.2;
        }

        .fill {
            position: absolute;
            bottom: 0;
            background-position-y: bottom;
            width: 100%;
            height: 50%;
            background-image: url("/game/btn_actor.png");
            background-size: 100% auto;
            background-repeat: no-repeat;
        }

        .icon {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: center;
        }
    }
    .actor-button:active.enabled {
        animation: button-down 0.1s;
        animation-fill-mode: forwards;
    }
    .actor-button.disabled {
        opacity: 0.2;
    }

    @keyframes button-down {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(1rem);
        }
    }

    #game-container {
        position: relative;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: bottom center;

        #gesture-container {
            pointer-events: none;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
        }

        .score-label {
            color: #242e4d;
            position: absolute;
            top: 60%;
            left: 60%;
            font-size: 3rem;
            width: 20rem;
            text-align: center;
            background-image: url("/game/score_label_background.png");
            background-size: cover;
            filter: drop-shadow(1rem 1rem 0px rgba(0, 0, 0, 0.18));
        }

        .score-label-anim {
            transform: scale(0) rotateZ(-10deg);
        }

        .score-label-anim-enter {
            transform: scale(0) rotateZ(-10deg);
            transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        .score-label-anim-enter-active {
            transform: scale(1) rotateZ(0);
        }

        #wait-message {
            transform-origin: center center;
            position: absolute;
            width: 100%;
            top: 40%;
            left: 0;
            z-index: 4;
            pointer-events: none;
            .label {
                color: #ffd949;
                font-size: 5rem;
            }
        }

        .wait-message {
            opacity: 0;
        }

        .wait-message-enter {
            opacity: 0;
            transition: opacity 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        .wait-message-enter-active {
            opacity: 1;
        }

        .wait-message-exit {
            opacity: 1;
        }

        .wait-message-exit-active {
            opacity: 0;
            transition: opacity 300ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
        }
    }

    @mixin shadow($right, $down, $blur, $size, $color) {
        -webkit-box-shadow: $right $down $blur $size $color;
        box-shadow: $right $down $blur $size $color;
    }

    .progress-bar {
        position: absolute;
        height: 2.25rem;
        border-radius: 1.25rem;
        -webkit-border-radius: 1.25rem;
        background: rgb(83, 27, 1);
        background: linear-gradient(0deg, rgba(83, 27, 1, 1) 0%, rgba(205, 90, 25, 1) 100%);
        width: 100%;
        overflow: hidden;
        pointer-events: none;
        z-index: 1;
        @include shadow(0.35rem, 0.4rem, 0px, 0px, rgba(0, 0, 0, 0.2));
        
        .fill {
            position: absolute;
            left: 0;
            width: 30%;
            height: 100%;
            background: rgb(195, 92, 4);
            background: linear-gradient(0deg, rgba(195, 92, 4, 1) 0%, rgba(248, 201, 88, 1) 100%);
            transition-duration: 0.1s;
        }
    }
    .progress-bar.hidden {
        opacity: 0;
    }

    #round-routine-active-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 90;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-size: 4rem;
            color: white;
            text-shadow: 1rem 1rem rgba(0, 0, 0, 0.514);
            padding: 3rem;
        }
    }
}

@keyframes scale-easeOutElastic {
    0% {
        transform: scale(1);
    }

    16% {
        transform: scale(-0.32);
    }

    28% {
        transform: scale(0.13);
    }

    44% {
        transform: scale(-0.05);
    }

    59% {
        transform: scale(0.02);
    }

    73% {
        transform: scale(-0.01);
    }

    88% {
        transform: scale(0);
    }

    100% {
        transform: scale(0);
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-16 17:44:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */

.scale-up-center {
    -webkit-animation: scale-up-center 0.4s ease-in-out infinite alternate both;
    animation: scale-up-center 0.4s ease-in-out infinite alternate both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.rotate-center {
    -webkit-animation: rotate-center 0.6s ease-in-out infinite alternate both;
    animation: rotate-center 0.6s ease-in-out infinite alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-17 8:34:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    100% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
    100% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
}

.rotate-scale-up-center {
    -webkit-animation: scale-up-center 0.4s ease-in-out infinite alternate both,
        rotate-center 0.6s ease-in-out infinite alternate both;
    animation: scale-up-center 0.4s ease-in-out infinite alternate both,
        rotate-center 0.6s ease-in-out infinite alternate both;
}

#lane-menu {
    width: 100%;
    position: absolute;
    top: 15rem;
    left: 0;
    z-index: 3;

    #lane-car-container {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
            height: 5rem;
        }
    }

    input[type="button"] {
        font-size: 3rem;
        margin: 1rem;
        border-radius: 5rem;
        padding: 1rem 2rem;
    }
}

audio {
    width: 0;
    height: 0;
    pointer-events: none;
}
