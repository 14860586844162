@mixin text-stroke($color: white, $size: 0.2rem) {
    text-shadow:
      -#{$size} -#{$size} 0 $color,
       0        -#{$size} 0 $color,
       #{$size} -#{$size} 0 $color,
       #{$size}  0        0 $color,
       #{$size}  #{$size} 0 $color,
       0         #{$size} 0 $color,
      -#{$size}  #{$size} 0 $color,
      -#{$size}  0        0 $color;
}

#end-page {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #4d628d;
    background-image: url(/background_pattern.png), linear-gradient(0deg, #4d628d 0%, #34425f 100%);
    font-family: 'bulo-rounded';
    text-transform: uppercase;
    color: black;
    overflow: hidden;
    user-select: none;

    #confirm {
        position: absolute;
        bottom: -1rem;
        right: 0.75rem;
        width: 35%;
        height: 22%;
        background-color: transparent;
        border: 0;
        background-image: url("/login/btn_continue.png");
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
        transform: rotate(-12deg);
    }
    #confirm.hidden {
        display: none;
    }
    #confirm.visible {
        display: inherit;
        animation: confirm-appear-keyframes 0.1s ease-out;
    }

    @keyframes confirm-appear-keyframes {
        0% {
            opacity: 0;
            transform: scale(0.9) rotate(-12deg);
        }
        75% {
            opacity: 1;
            transform: scale(1.05) rotate(-12deg);
        }
        100% {
            opacity: 1;
            transform: scale(1) rotate(-12deg);
        }
    }

    #bottom-bar {
        position: absolute;
        background-image: url("/game/player_name_background.png");
        background-size: cover;
        background-position: center;
        font-size: 3rem;
        line-height: 4rem;
        height: 5rem;
        width: 110%;
        left: -5%;
        color: #000000;
        position: absolute;
        bottom: 14%;
        transform: rotate(-12deg);

        p {
            font-size: 3rem;
            padding: 0.5rem 4.5rem;
            @include text-stroke();
        }
    }
}
