#app-disconnected-page {
    
    width: 100%;
    height: 100%;

    #message-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        text-align: center;
    }
    
    #message {
        background-color: white;
        padding: 2rem;
        font-size: 2rem;
        border-radius: 2rem;

        p {
            margin: 1rem;
        }
    }

    input[type=button] {
        font-family: 'komika-axis-regular';
        color: white;
        background-color: #252018;
        border: none;
        font-size: 2rem;
        padding: 1.5rem 3rem;
        margin: 1rem;
        border-radius: 1rem;
    }
}