#loading-page {

    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    #loading-bar {
        position: relative;
        width: 80%;
        height: 1rem;
        background-color: #242d41;
        overflow: hidden;

        #loading-bar-fill {
            position: absolute; 
            top: 0;
            left: 0;
            bottom: 0;
            width: 50%;
            background-color: #6d80a6;
        }
    }
}