#pedestrian-menu,
#car-menu {
    position: absolute;
    bottom: 0rem;
    background-size: cover;
    transform-origin: center bottom;

    .close-button {
        position: absolute;
        top: -2rem;
        right: 0;
        background-color: white;
        width: 4rem;
        height: 4rem;
        border-radius: 2rem;
        border: 0;
    }

    input,
    textarea {
        font-size: 2rem;
        display: block;
    }

    #pedestrian-message {
        position: absolute;
        padding: 4rem;
        width: 100%;
        box-sizing: border-box;
        top: 0;
        bottom: 10rem;
        background: transparent;
        border: 0;
        resize: none;
        overflow: hidden;
    }

    .action-button {
        position: relative;
        background-color: transparent;
        background-image: url("/game/btn_actor.png");
        background-size: contain;
        background-repeat: no-repeat;
        border: 0;
        width: 10rem;
        height: 10rem;
        margin: .5rem;
        color: white;
        display: inline-block;
    }
    .action-button:disabled {
        opacity: 0.5;
    }
}

#car-menu {
    height: 12rem;
    left: 5rem;
    right: 5rem;
    bottom: 5rem;

    .action-button-container {
        width: 100%;
        height: 100%;
    }
}

#pedestrian-menu { 
    right: 4rem;
    bottom: 27rem;

    .action-button-container {
        position: absolute;
        bottom: 0; right: 0;
    }
}

.action-button:active {
    transform: translatey(0.5rem);
}

#type-window {
    position: absolute;
    top: 5rem;
    left: 1rem;
    right: 1rem;
    z-index: 10;

    .background {
        width: 100%;
    }

    #type-form {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: rotate(2deg);

        textarea {
            background: transparent;
            position: absolute;
            top: 15px;
            bottom: 50px;
            left: 0;
            width: 100%;
            padding: 5rem 5rem 7rem 5rem;
            border: 0;
            font-size: 3rem;
            box-sizing: border-box;
            resize: none;
            text-align: center;
        }

        input[type="submit"] {
            position: absolute;
            bottom: 3rem;
            padding: 1rem;
            margin: auto auto;
            font-size: 0;
            width: 10rem;
            height: 10rem;
            background: transparent;
            border: 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("/game/next_button_wostick.png");
        }
    }

    .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: white;
        border-radius: 3rem;
        font-size: 3rem;
        width: 6rem;
        height: 6rem;
        border: 0;
    }
}

#type-form {
    position: relative;
    width: 100%;
    height: 100%;
}

.pop {
    transform: scale(0) rotateZ(-10deg);
}

.pop-enter {
    transform: scale(0) rotateZ(-10deg);
    transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.pop-enter-active {
    transform: scale(1) rotateZ(0);
}

.pop-exit {
    transform: scale(1) rotateZ(0);
}

.pop-exit-active {
    transform: scale(0);
    transition: transform 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
