@font-face {
    font-family: 'dumbnerd-regular';
    src: url('fonts/dumbnerd-webfont.woff2') format('woff2'),
         url('fonts/dumbnerd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'komika-axis-regular';
    src: url('fonts/komikax_-webfont.woff2') format('woff2'),
         url('fonts/komikax_-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bulo-rounded';
    src: url('fonts/bulorounded-bolditalic-webfont.woff2') format('woff2'),
         url('fonts/bulorounded-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'janitor';
    src: url('fonts/janitor-webfont.woff2') format('woff2'),
         url('fonts/janitor-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}