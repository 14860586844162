#events-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    
    @keyframes blink-animation {
        0% {
            box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0);
        }
        100% {
            box-shadow: 0px 0px 10px 2px rgb(255, 255, 255);
        }
    }
    
    #scale-field {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
    }
    
    #player {
        width: 30rem;
        height: 30rem;
        background-image: url("/game/player_cone.png");
        background-size: 100% 100%;
        margin-left: -15rem;
        transition: transform 0.25s ease;
        transform-origin: bottom center;
    }
    
    .event-button.locked {
        opacity: 0.25;
        pointer-events: none;
    }
    .event-button.disabled {
        filter: brightness(35%);
        pointer-events: none;
    }

    .event-button {
        position: relative;
        width: 8rem;
        height: 8rem;
        margin: 0 0 -4rem -4rem;
        display: flex;
        justify-content: center;
        
        .button-container {
            position: relative;
            width: 85%;
            height: 85%;
            background-image: url("/game/buttons/btn_actor.png");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            box-shadow: 0.35rem 0.75rem 0 rgba(0, 0, 0, 0.25);
            border-radius: 100%;
            
            .icon {
                position: absolute;
                top: 5%;
                left: 5%;
                width: 90%;
                height: 90%;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                filter: drop-shadow(0.2rem 0.35rem 0 rgba(0, 0, 0, 0.5));
            }

            .blink-container.blink {
                animation: blink-animation 3s infinite alternate ease-in-out;
                border-radius: 50%;
                width: 100%;
                height: 100%;
            }
        }
        .button-container.event {
            background-image: url("/game/buttons/btn_special.png");
        }

        .progress-bar {
            top: -3rem;
        }
    }

    .feedback-items-container {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;

        .feedback-item {
            position: absolute;
            top: 0;
            left: 0;
            width: 4rem;
            height: 4rem;
            color: rgb(255, 255, 255);
            font-size: 2rem;
            margin: -2rem 0 0 -2rem;
            animation: event-feedback-animation 0.3s ease-out;
            animation-fill-mode: forwards;
            transform: scale(0, 0);
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            z-index: 2;
        }

        @keyframes event-feedback-animation {
            0% {
                transform: translate(0, 0) scale(0.5, 0.5);
                opacity: 1;
            }
            90% {
                opacity: 1;
            }
        }
    }

    .event-start-feedback {
        pointer-events: none;
        position: absolute;
        width: 8rem;
        height: 8rem;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        top: 0;
        left: -4rem;
        transform: scale(2, 2);
        animation: feedback-animation 0.3s ease-out;
        animation-fill-mode: forwards;
    }

    @keyframes feedback-animation {
        0% {
            transform: scale(0, 0);
            opacity: 1;
        }
        80% {
            opacity: 1;
        }
        100% {
            transform: scale(2, 2);
            opacity: 0;
        }
    }

    input {
        display: block;
        margin: 0.5rem;
        font-size: 2rem;
    }

    .wobble {
        position: relative;
        animation: wobble-keyframes 0.3s ease-out;
        animation-iteration-count: infinite;
    }

    @keyframes wobble-keyframes {
        0% {
            transform: rotate(0);
        }
        10% {
            transform: scale(1.05);
        }
        20% {
            transform: rotate(4deg) translateY(-12px);
        }
        40% {
            transform: rotate(2deg) translateY(0);
        }
        60% {
            transform: rotate(-2deg) translateY(-8px);
        }
        80% {
            transform: rotate(-1deg) translateY(0);
        }
    }
}
