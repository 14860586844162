@mixin background($url) {
    background-image: url($url);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#tutorial-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 150;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 1.75rem;
    animation: tutorial-panel-keyframes 0.2s ease 0.1s 1 normal forwards;
    
    #tutorial-container {
        position: relative;
        width: 100%;
        height: 60vh;
        @include background('/game/final-score-background.png');
        
        #tutorial-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 12rem 6rem;

            #tutorial-missions {
                #images {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .image {
                        width: 25rem;
                        height: 20rem;
                        transform: rotate(-5deg);
                        @include background('/game/tutorial-missions.png');
                    }
                    .arrow {
                        width: 10rem;
                        height: 10rem;
                        margin-top: 7rem;
                        transform: rotate(-130deg);
                        filter: brightness(0);
                        @include background('/game/arrow.png');
                    }
                }

                .text {
                    margin-top: 1rem;
                    width: 35rem;
                    height: 7rem;
                    transform: rotate(-2deg);
                }
            }

            #tutorial-buttons {
                #images {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .image {
                        width: 22rem;
                        height: 13rem;
                        transform: rotate(4deg);
                        @include background('/game/tutorial-buttons.png');
                    }
                    .arrow {
                        width: 10rem;
                        height: 10rem;
                        margin-top: 5rem;
                        transform: rotate(-30deg) scaleY(-1);
                        filter: brightness(0);
                        @include background('/game/arrow.png');
                    }
                }
                
                .text {
                    font-size: 1.65rem;
                    margin-top: 3rem;
                    width: 35rem;
                    height: 7rem;
                    transform: rotate(3deg);
                }
            }

            #tutorial-special {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .text {
                    width: 35rem;
                    height: 10rem;
                    transform: rotate(-2deg);
                }
                .image {
                    margin-top: -2rem;
                    width: 40rem;
                    height: 10rem;
                    transform: rotate(3deg);
                    @include background('/game/tutorial-special.png');
                }
                .arrow {
                    width: 10rem;
                    height: 10rem;
                    transform: rotate(120deg) scale(0.7, -0.7);
                    filter: brightness(0);
                    @include background('/game/arrow.png');
                }
            }
            
            #tutorial-special-multispawn {
                #images {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .image {
                        width: 22rem;
                        height: 13rem;
                        transform: rotate(4deg);
                        @include background('/game/buttons/btn_multispawn.png');
                    }
                    .arrow {
                        width: 10rem;
                        height: 10rem;
                        margin-top: 5rem;
                        transform: rotate(-30deg) scaleY(-1);
                        filter: brightness(0);
                        @include background('/game/arrow.png');
                    }
                }
                
                .text {
                    font-size: 1.65rem;
                    margin-top: 3rem;
                    width: 35rem;
                    height: 7rem;
                    transform: rotate(3deg);
                }
            }

            #tutorial-special-godzilla {
                #images {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .image {
                        width: 22rem;
                        height: 13rem;
                        transform: rotate(-4deg);
                        @include background('/game/buttons/btn_godzilla.png');
                    }
                    .arrow {
                        width: 10rem;
                        height: 10rem;
                        margin-top: 5rem;
                        transform: rotate(220deg);
                        filter: brightness(0);
                        @include background('/game/arrow.png');
                    }
                }
                
                .text {
                    font-size: 1.65rem;
                    margin-top: 3rem;
                    width: 35rem;
                    height: 7rem;
                    transform: rotate(-3deg);
                }
            }

            #tutorial-special-meteor {
                #images {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .image {
                        width: 22rem;
                        height: 13rem;
                        transform: rotate(4deg);
                        @include background('/game/buttons/btn_meteor.png');
                    }
                    .arrow {
                        width: 10rem;
                        height: 10rem;
                        margin-top: 6rem;
                        transform: rotate(-35deg) scaleY(-1);
                        filter: brightness(0);
                        @include background('/game/arrow.png');
                    }
                }
                
                .text {
                    font-size: 1.65rem;
                    margin-top: 4rem;
                    width: 35rem;
                    height: 7rem;
                    transform: rotate(1deg);
                }
            }

            &.animate {
                animation: tutorial-content-keyframes 0.2s ease;
            }

            @keyframes tutorial-content-keyframes {
                0% {
                    transform: scale(0);
                }
                70% {
                    transform: scale(1.1);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
        
        #buttons {
            position: absolute;
            bottom: 12%;
            width: 100%;
            display: flex;
            justify-content: center;

            .progression-button {
                width: 8rem;
                height: 5rem;
                margin: 0 3rem;
                background: rgb(195, 92, 4);
                background: linear-gradient(0deg, rgba(195, 92, 4, 1) 0%, rgba(248, 201, 88, 1) 100%);
                border-radius: 0.5rem;
                border: 0.4rem solid white;
                box-shadow: 0.35rem 0.75rem 0 rgba(0, 0, 0, 0.25);
                transition-duration: 0.15s;
                transform: rotate(-3deg);
            
                &#skip {
                    margin-top: 0.75rem;
                    .icon {
                        @include background('/game/icons/icn_go_go_go.png');
                    }
                }
                &#continue {
                    .icon {
                        @include background('/game/icons/icn_go.png');
                        transform: scale(0.75);
                    }
                }
                .icon {
                    width: 100%;
                    height: 100%;
                }
    
                &.disabled{
                    pointer-events: none;
                    filter: brightness(0.3);
                }
            }
        }
    
        &.animate {
            animation: tutorial-container-keyframes 0.1s ease;
        }

        @keyframes tutorial-container-keyframes {
            0% {
                transform: scale(1);
            }
            70% {
                transform: scale(1.05);
            }
            100% {
                transform: scale(1);
            }
        }

    }
    
    @keyframes tutorial-panel-keyframes {
        0% {
            background-color: rgba(0, 0, 0, 0);
        }
        100% {
            background-color: rgba(0, 0, 0, 0.9);
        }
    }
}

.tutorial {
    transform: scale(0) rotateZ(-10deg);
}

.tutorial-enter {
    transform: scale(0) rotateZ(-10deg);
    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.tutorial-enter-active {
    transform: scale(1) rotateZ(0);
}

.tutorial-exit {
    transform: scale(1) rotateZ(0);
    background-color: rgba(0, 0, 0, 0) !important;
}

.tutorial-exit-active {
    transform: scale(0);
    transition: transform 300ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    background-color: rgba(0, 0, 0, 0) !important;
}
