#traffic-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    pointer-events: none;

    .client-entity {
        width: 6rem;
        height: 6rem;
        margin: -3rem;
        transition: bottom 0.25s, left 0.25s;
        transition-timing-function: linear;

        .rotation-container {
            width: 100%;
            height: 100%;
        }

        .icon {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            transition: bottom 0.25s, left 0.25s;
            transition-timing-function: linear;
        }
        .icon.car {
            transform: scale(0.8);
        }
        .icon.bus {
            transform: scale(1.2);
        }
        .icon.crash {
            background-image: url('/game/icons/icn_crash.png');
            animation: crash-icon-keyframes 0.4s ease-in-out;
            animation-iteration-count: infinite;
        }

        @keyframes crash-icon-keyframes {
            0% {
                transform: scale(1.3);
            }
            50% {
                transform: scale(1.4);
            }
            100% {
                transform: scale(1.3);
            }
        }
    }
}
