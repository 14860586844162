#login-page {
    height: 100%;
    position: relative;
    text-align: center;
    font-size: 2rem;

    input, label{
        display: block;
    }

    #login-form, #name-form {
        position: absolute;
        background-color: #b78c5e;
        border-top: 0.5vh solid #d2a879;
        background-image: url('/login/form_background.png');
        background-size: cover;
        bottom: 0;
        width: 100%;
        height: 60%;

        label {
            font-size: 3rem;
            padding: 2rem 2rem 0rem 2rem;
        }

        #submit {
            position: absolute;
            bottom: 0px;
            left: 25%;
            width: 50%;
            height: 47.5%;
            background-color: transparent;
            border: 0;
            background-image: url('/login/btn_continue.png');
            background-size: contain;
            background-position-x: center;
            background-repeat: no-repeat;
            font-size: 0;
        }

        .connectError:empty::after {
            content: '.';
            visibility: hidden;
        }
    }

    #login-form {
        #dennis {
            position: absolute;
            top: -40.25%;
            background-image: url('/login/jam_code_dennis.png');
            background-size: contain;
            background-position-x: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 50%;
        }
    }

    #name-form {
        #dennis {
            position: absolute;
            top: -40.25%;
            background-image: url('/login/player_name_dennis.png');
            background-size: contain;
            background-position-x: center;
            background-repeat: no-repeat;
            width: 100%;
            height: 50%;
        }
    }

    #jam-code {
        transform: rotate(-3deg);
        letter-spacing: 0.5em;
    }

    #player-name {
        transform: rotate(3deg);
    }

    input[type=text] {
        background-color: transparent;
        border: 0px;
        width: 100%;
        padding: 1em 0;
        background-image: url('/login/jam_code_background.png');
        background-size: contain;
        background-position-x: center;
        background-repeat: no-repeat;
        color: #242e4d;
        text-align: center;
        font-size: 2em;
        font-family: 'komika-axis-regular';
        text-transform: uppercase;
    }

    #logo{
        position: absolute;
        width: 40%;
        height: 20%;
        top: 1rem;
        left: 0rem;

        background-image: url('/login/logo.png');
        background-size: contain;
        background-position-x: center;
        background-repeat: no-repeat;
    }

    #mute-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 3rem;
        height: 3rem;
        background-color: rgba(116, 160, 211, 0.25);
        border-radius: 2rem;
        -webkit-border-radius: 2rem;
        padding: 0.3rem;

        #icon {
            width: 100%;
            height: 100%;
            background-image: url('/game/icons/icn_audio_volume.png');
            background-size: 2rem;
            background-position: center center;
            background-repeat: no-repeat;

            &.muted {
                background-image: url('/game/icons/icn_audio_mute.png');
            }
        }
    }
}
