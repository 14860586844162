#triggered-event-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    transform-origin: center center;
    pointer-events: none;
}

.triggered-event {
    transform: scale(0) rotateZ(-10deg);
}

.triggered-event-enter {
    transform: scale(0) rotateZ(-10deg);
    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.triggered-event-enter-active {
    transform: scale(1) rotateZ(0);
}

.triggered-event-exit {
    transform: scale(1) rotateZ(0);
}

.triggered-event-exit-active {
    transform: scale(0);
    transition: transform 300ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

#triggered-event-image {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}