#new-player-message {
    position: absolute;
    top: 3rem;
    z-index: 100;
    font-size: 2rem;
    width: 100%;
    text-align: center;
    user-select: none;

    #message {
        display: inline-block;
        background-color: white;
        padding: 1rem 3rem;
        border-radius: 2rem;
    }
}

.new-player-message {
    transform: translateY(-10rem);
}

.new-player-message-enter {
    transform: translateY(-10rem);
    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.new-player-message-enter-active {
    transform: translateY(0rem);
}

.new-player-message-exit {
    transform: translateY(0rem);
}

.new-player-message-exit-active {
    transform: translateY(-10rem);
    transition: transform 300ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

#orientation-message {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 150;
    background-color: rgba(0, 0, 0, 0.8);
    user-select: none;
    
    #message {
        color: white;
        font-size: 4rem;
        text-align: center;
        text-shadow: 1rem 1rem rgba(0, 0, 0, 0.514);
        margin-top: 6rem;
        padding: 2rem;
    }
}

.orientation-message {
    opacity: 1;
}

.orientation-message-enter {
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.orientation-message-enter-active {
    opacity: 1;
}

.orientation-message-exit {
    opacity: 1;
}

.orientation-message-exit-active {
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}