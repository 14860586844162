#round-cleared-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;

    #round-text-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        #round-cleared-text {
            font-size: 7rem;
            text-align: center;
            padding: 0 5rem;
            color: rgb(255, 225, 57);
            text-shadow: 1rem 1rem rgba(0, 0, 0, 0.5);

            &.animate {
                animation: text-animate-keyframes 0.3s ease-out;
                animation-iteration-count: 1;
            }
            &.counting {
                font-size: 11rem;
            }
            
            @keyframes text-animate-keyframes {
                0% {
                    transform: scale(0);
                }
                70% {
                    transform: scale(1.4);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
    }
}

.round-cleared {
    transform: scale(0) rotateZ(-10deg);
}

.round-cleared-enter {
    transform: scale(0);
    transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.round-cleared-enter-active {
    transform: scale(1);
}

.round-cleared-exit {
    transform: scale(1);
}

.round-cleared-exit-active {
    transform: scale(0);
    transition: transform 300ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
