@mixin shadow($right, $down, $blur, $size, $color) {
    -webkit-box-shadow: $right $down $blur $size $color;
    box-shadow: $right $down $blur $size $color;
}

* {
    margin: 0px;
    padding: 0px;
    touch-action: manipulation;
}

html, body, #root {
    font-size: 1vh;
    width: 100%;
    height: 100%;
}

img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

*:focus {
    outline: none;
}

body {
    background: #000000;
    color: #4e4332;
    font-family: 'komika-axis-regular';
}

input, textarea {
    font-family: 'komika-axis-regular';
}

.App {
    position: relative;
    background: rgb(77,98,141);
    background-image: url('/background_pattern.png'), linear-gradient(0deg, rgba(77,98,141,1) 0%, rgba(52,66,95,1) 100%);
    margin: 0 auto;
    max-width: 56vh;
    height: 100%;
}