#special-event-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    pointer-events: none;

    #special-event-bar {
        position: absolute;
        bottom: 3.5%;
        width: 100%;
        height: 2.25rem;
        pointer-events: all;

        #special-event-container {
            height: 100%;
            padding: 0 5rem;
            display: flex;
    
            .progress-bar-container {
                width: 92%;
                height: 100%;
                margin: -3rem;
                border-image: url(/game/special-event-bar-stroke.png) 90;
                border-image-width: 4rem;
                border-width: 3.2rem 2.5rem;
                border-style: solid;
                
                .progress-bar {
                    position: relative;
                    margin-right: -0.75rem;
                    z-index: unset;
                    box-shadow: none;
                    border-radius: 0.8rem;
                    -webkit-border-radius: 0.8rem;
                }
            }
        
            .event-icon {
                position: absolute;
                top: -4.25rem;
                right: 0.5rem;
                width: 10rem;
                height: 10rem;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }
            .event-icon.disabled {
                filter: brightness(50%);
            }
        }
    }

    #special-event-active {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: all;

        #tv-image {
            position: relative;
            width: 50%;
            height: 18%;
            margin: 4rem auto;
            background-image: url('/game/tv.png');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            animation: tv-image-keyframes 0.5s ease-in-out;
            animation-iteration-count: infinite;
        }

        #event-image {
            position: relative;
            width: 100%;
            height: 50%;
            margin: 5rem auto;
            
            #image-container {
                position: relative;
                width: 100%;
                height: 100%;
                animation: scale-in-keyframes 0.3s ease;
                animation-iteration-count: 1;

                #image {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;
    
                    animation: event-image-keyframes 0.4s ease-in-out;
                    animation-iteration-count: infinite;
                }
                #image.meteor {
                    animation: event-image-keyframes-meteor 0.2s ease-in-out;
                    animation-iteration-count: infinite;
                }
            }
        }
        #event-image.godzilla {
            transform: scale(1.3);
        }
        #event-image.meteor {
            transform: scale(1.4);
        }
        
        #event-background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: black;
            opacity: 0.4;
        }
        #event-background.godzilla {
            background-color: unset;
            opacity: 0.7;
        }
        #event-background.meteor {
            background-color: unset;
            opacity: 0.4;
        }
    }
}

#special-event-bar.ready {
    animation: event-bar-ready-keyframes 0.4s ease-out;
    animation-iteration-count: infinite;
}

@keyframes event-bar-ready-keyframes {
    0% {
        transform: rotate(0);
    }
    10% {
        transform: scale(1.02);
    }
    20% {
        transform: rotate(0.5deg) scale(1.05);
    }
    40% {
        transform: rotate(0.25deg);
    }
    60% {
        transform: rotate(-0.5deg);
    }
    80% {
        transform: rotate(-0.25deg);
    }
}

@keyframes tv-image-keyframes {
    0% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(-5deg);
    }
}

@keyframes event-image-keyframes {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes event-image-keyframes-meteor {
    0% {
        transform: translate(0 0);
    }
    25% {
        transform: translate(0.25rem, 0.6rem);
    }
    50% {
        transform: translate(0 0);
    }
    75% {
        transform: translate(-0.25rem, 0.2rem);
    }
    100% {
        transform: translate(0 0);
    }
}

@keyframes scale-in-keyframes {
    0% {
        transform: scale(0);
    }
    80% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}

.special-event-active-panel {
    opacity: 0;
}

.special-event-active-panel-enter {
    opacity: 0;
    transition: opacity 0.25s ease;
}

.special-event-active-panel-enter-active {
    opacity: 1;
}

.special-event-active-panel-exit {
    opacity: 1;
}

.special-event-active-panel-exit-active {
    opacity: 0;
    transition: opacity 0.25s ease;
}
